import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src477529347/src/autopatcher-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Player } from "src/gatsby-theme-docz/components/Player";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "bootcamp-videos"
    }}>{`Bootcamp videos`}</h1>
    <p>{`Youtube playlist with all videos can be found `}<a parentName="p" {...{
        "href": "https://www.youtube.com/playlist?list=PLXDgEAmXee82mh7xgfvvkC85j1N1mISuT"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "introduction-to-autopatcher"
    }}>{`Introduction to AutoPatcher`}</h2>
    <Player url="https://www.youtube.com/embed/-g9NhxrWC9k" title="AutoPatcher UI Overview" timestamps={[{
      timestamp: "0:20",
      name: "User login"
    }, {
      timestamp: "0:50",
      name: "AutoPatcher dashboard"
    }, {
      timestamp: "1:56",
      name: "AutoPatcher UI sections overview"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/wUJ4NPIuvOA" title="AWS machine registration" timestamps={[{
      timestamp: "0:38",
      name: "Adding new AWS machine"
    }, {
      timestamp: "1:07",
      name: "Setting up SSM in IAM mode"
    }, {
      timestamp: "1:39",
      name: "Deploying CloudFormation Templates"
    }, {
      timestamp: "4:20",
      name: "Creating EC2 instance"
    }, {
      timestamp: "7:42",
      name: "Adding EC2 instance to AutoPatcher"
    }, {
      timestamp: "9:22",
      name: "Defining additional machine attributes"
    }, {
      timestamp: "11:00",
      name: "Note about missing ping status"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/FebUALM-5xI" title="Adding preexisting AWS machine" timestamps={[{
      timestamp: "0:20",
      name: "Attaching AutoPatcher Managed Policy"
    }, {
      timestamp: "0:54",
      name: "Machine availability in Systems Manager"
    }, {
      timestamp: "1:27",
      name: "Starting SSM Agent on the instance"
    }, {
      timestamp: "3:03",
      name: "Registering machine to AutoPatcher"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/_vXPwDAxArY" title="Adding machine without SSM agent preinstalled" timestamps={[{
      timestamp: "0:27",
      name: "List of machines with SSM agent preinstalled"
    }, {
      timestamp: "0:50",
      name: "Creating machine with CentOS"
    }, {
      timestamp: "2:10",
      name: "Installing SSM agent on CentOS"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/UO1IJWV5GLM" title="Adding hybrid instances (Azure/GCP/on-premise machines)" timestamps={[{
      timestamp: "0:25",
      name: "Setting up SSM in hybrid mode"
    }, {
      timestamp: "0:43",
      name: "Obtaining installer key for AP installer"
    }, {
      timestamp: "2:54",
      name: "Using AP installer to add the machine"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/pFwmzZBZG64" title="Create patching plan" timestamps={[{
      timestamp: "1:51",
      name: "Adding machine via List"
    }, {
      timestamp: "2:54",
      name: "Additional plan properties overview"
    }, {
      timestamp: "4:52",
      name: "Overview of created plan and its events"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/PQ6rJyRg91k" title="Adding machine to the plan using AP installer" timestamps={[{
      timestamp: "0:19",
      name: "Connecting to Windows machine in Azure"
    }, {
      timestamp: "0:56",
      name: "Downloading AP installer"
    }, {
      timestamp: "1:56",
      name: "Registering the machine along with adding it to the plans"
    }, {
      timestamp: "3:51",
      name: "Adding already registered machine to the plan using AP installer"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/o9VWHo-VQsI" title="Event tracking" timestamps={[{
      timestamp: "0:34",
      name: "Overview of patching report"
    }, {
      timestamp: "0:52",
      name: "Generating PDF report"
    }, {
      timestamp: "1:52",
      name: "Repeat failed patching using partial patching"
    }, {
      timestamp: "4:54",
      name: "Re-generate reports based on new update log"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/de_yBLwkNOA" title="Dynamic plans" timestamps={[{
      timestamp: "0:10",
      name: "Preparing EC2 instances with tags"
    }, {
      timestamp: "1:31",
      name: "Configuring tags for machines in dynamic plan"
    }, {
      timestamp: "3:51",
      name: "Overview of the created dynamic plan"
    }, {
      timestamp: "4:30",
      name: "Prerequisites for the machines patched with dynamic plans"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/6pXrGbV7orI" title="Patching pipelines" timestamps={[{
      timestamp: "0:32",
      name: "Defining pipeline steps"
    }, {
      timestamp: "3:06",
      name: "Using machines that were added in a dynamic plan earlier"
    }, {
      timestamp: "5:16",
      name: "Defining step delay"
    }, {
      timestamp: "6:16",
      name: "Created pipeline overview"
    }, {
      timestamp: "7:42",
      name: "Pipeline step policies overview"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/2xiuTjP8sCw" title="Baseline patching" timestamps={[{
      timestamp: "1:07",
      name: "Example baseline patching configuration"
    }, {
      timestamp: "4:22",
      name: "Overview of created events for baseline patching"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/DJLCNasKfJ0" title="Notification groups" timestamps={[{
      timestamp: "0:42",
      name: "Notification channels overview"
    }, {
      timestamp: "1:42",
      name: "Creating example email notification"
    }, {
      timestamp: "2:15",
      name: "Notification group events and severity levels"
    }, {
      timestamp: "4:08",
      name: "Attaching created notification group to the plan"
    }, {
      timestamp: "5:13",
      name: "Creating example email and slack notification for particular events"
    }, {
      timestamp: "7:18",
      name: "Attaching created notification group to the pipeline"
    }, {
      timestamp: "9:16",
      name: "Overview of received notifications"
    }]} mdxType="Player" />
    <h2 {...{
      "id": "advanced-features"
    }}>{`Advanced features`}</h2>
    <Player url="https://www.youtube.com/embed/R8xdv18vk3I" title="Role-Based Access Control in AutoPatcher" timestamps={[{
      timestamp: "0:28",
      name: "Assignments"
    }, {
      timestamp: "0:43",
      name: "Roles"
    }, {
      timestamp: "0:50",
      name: "Creating example read-only role for machine"
    }, {
      timestamp: "1:55",
      name: "Attaching created role to user"
    }, {
      timestamp: "3:59",
      name: "Overview of access limitation due to permission change"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/MHSJxgcRYe0" title="API keys" timestamps={[{
      timestamp: "0:33",
      name: "Creating API key"
    }, {
      timestamp: "1:40",
      name: "Using API key with AutoPatcher CLI"
    }, {
      timestamp: "2:35",
      name: "Adding permissions to the API key"
    }, {
      timestamp: "3:58",
      name: "Removing API key"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/umNMMOyZjac" title="Executing plans and pipelines on demand" timestamps={[{
      timestamp: "0:09",
      name: "Executing plans on demand"
    }, {
      timestamp: "1:31",
      name: "Executing pipelines on demand"
    }, {
      timestamp: "2:13",
      name: "Details of the single pipeline execution (group of events)"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/3ymKuz2uk2s" title="Hooks" timestamps={[{
      timestamp: "0:17",
      name: "Example plan with hooks for starting and stopping the machines"
    }, {
      timestamp: "1:34",
      name: "Hooks execution analysis"
    }, {
      timestamp: "3:13",
      name: "Overview of the hooks results"
    }, {
      timestamp: "3:58",
      name: "Plan configuration"
    }, {
      timestamp: "5:20",
      name: "Hooks implementation"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/iiHBG97pUXM" title="Host hooks" timestamps={[{
      timestamp: "0:15",
      name: "Example plan with host hook configuration"
    }, {
      timestamp: "0:56",
      name: "Host hook types"
    }, {
      timestamp: "1:28",
      name: "Preparing example script used as a host hook"
    }, {
      timestamp: "5:28",
      name: "Results of patching with example host hook"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/fGG9fNSmZwc" title="Moving existing plan into pipeline" timestamps={[{
      timestamp: "0:22",
      name: "Moving plan to a new step in the pipeline"
    }, {
      timestamp: "1:20",
      name: "Adding plan to existing pipeline step"
    }, {
      timestamp: "4:00",
      name: "Other example of moving plan to pipeline"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/Sw1hNMWFodk" title="Execute pipeline steps immediately after each other" timestamps={[{
      timestamp: "0:18",
      name: "Configuring example pipeline with \"run immediately\" option"
    }, {
      timestamp: "2:11",
      name: "Pipeline results"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/uG572AeulPs" title="On hold window" timestamps={[{
      timestamp: "0:08",
      name: "Creating example plan with on hold window"
    }, {
      timestamp: "1:32",
      name: "Overview of created events"
    }, {
      timestamp: "2:00",
      name: "Removing on hold window"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/6bITOga2nUs" title="Plans with manual approval" timestamps={[{
      timestamp: "0:10",
      name: "Creating example plan with manual approval option"
    }, {
      timestamp: "0:47",
      name: "Overview of created events"
    }, {
      timestamp: "1:21",
      name: "Approving events"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/pF3ZHRlkfCM" title="Installing only security updates for Linux machines" timestamps={[{
      timestamp: "1:28",
      name: "Creating example plan with linux security only option"
    }, {
      timestamp: "3:30",
      name: "Comparison of installed updates"
    }]} mdxType="Player" />
    <h2 {...{
      "id": "autopatcher-cli"
    }}>{`AutoPatcher CLI`}</h2>
    <Player url="https://www.youtube.com/embed/kVBVjIjMym4" title="Downloading and configuring CLI" timestamps={[{
      timestamp: "0:14",
      name: "Downloading and installing the AutoPatcher CLI"
    }, {
      timestamp: "1:35",
      name: "Configuring the AutoPatcher CLI"
    }, {
      timestamp: "3:24",
      name: "Overview of AutoPatcher CLI commands"
    }, {
      timestamp: "4:49",
      name: "Showing all machines"
    }, {
      timestamp: "5:03",
      name: "Showing a single machine"
    }, {
      timestamp: "5:27",
      name: "Removing a machine"
    }, {
      timestamp: "6:14",
      name: "Showing all plans"
    }, {
      timestamp: "6:32",
      name: "Modifying a plan"
    }, {
      timestamp: "8:40",
      name: "Bash autocompletion for the CLI"
    }, {
      timestamp: "10:59",
      name: "Force running a plan"
    }, {
      timestamp: "11:53",
      name: "Advanced transformation of the JSON output in command line (jq filter example)"
    }, {
      timestamp: "12:33",
      name: "Force running a pipeline"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/bSu2CtflFx8" title="Generating bundle PDF report for pipelines using CLI" timestamps={[{
      timestamp: "0:15",
      name: "Preparing input for the command"
    }, {
      timestamp: "4:22",
      name: "Generating the bundle PDF report"
    }, {
      timestamp: "4:53",
      name: "Generated report overview"
    }, {
      timestamp: "5:14",
      name: "Alternative version of the script"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/7KEhtFNyPFA" title="Example use case - removing machines from dynamic plan in bulk" mdxType="Player" />
    <Player url="https://www.youtube.com/embed/qCKmviyh2Yg" title="Example use case - removing machines based on matching their name using a pattern" mdxType="Player" />
    <Player url="https://www.youtube.com/embed/-DPhiIV4euc" title="Configuring AutoPatcher CLI for usage in CI/CD" timestamps={[{
      timestamp: "0:22",
      name: "Creating API key"
    }, {
      timestamp: "1:04",
      name: "Granting permissions to the API key"
    }, {
      timestamp: "1:50",
      name: "Exporting the API key in the running shell session to be used by CLI"
    }, {
      timestamp: "3:11",
      name: "Storing API key in the configuration file"
    }, {
      timestamp: "3:50",
      name: "Using API key as CLI argument"
    }]} mdxType="Player" />
    <h2 {...{
      "id": "autopatcher-api"
    }}>{`AutoPatcher API`}</h2>
    <Player url="https://www.youtube.com/embed/jMNaN-k3ctc" title="Intro to Altair GraphQL client" timestamps={[{
      timestamp: "0:32",
      name: "Installing Altair GraphQL client"
    }, {
      timestamp: "1:23",
      name: "Configuring Altair client"
    }, {
      timestamp: "1:43",
      name: "Creating an API key"
    }, {
      timestamp: "3:08",
      name: "Extracting customer ID"
    }, {
      timestamp: "5:42",
      name: "Inspecting AutoPatcher GraphQL API schema"
    }, {
      timestamp: "6:53",
      name: "Example GraphQL query"
    }]} mdxType="Player" />
    <Player url="https://www.youtube.com/embed/IpEB_Jmzrf8" title="Sample script for scheduling one time patching via GraphQL API" timestamps={[{
      timestamp: "0:42",
      name: "Script implementation"
    }, {
      timestamp: "6:36",
      name: "Results of the script"
    }]} mdxType="Player" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      